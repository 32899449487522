import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header id="header">
    <div className="div-header">
      <Link className="nav-title" to="/">
        <h2 className="h2-title">OH.</h2>
      </Link>
      <div className="nav-items">
        <Link className="nav-link" activeClassName="nav-link-active" to="/portfolio">Portfolio</Link>
        <Link className="nav-link" activeClassName="nav-link-active" to="/oriol-hilari-amat/">About</Link>
        <Link className="nav-link" activeClassName="nav-link-active" to="/contact">Contact</Link>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;