import React from 'react'
import Header from './components/Header'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

import "../styles/layout.css"
import "../styles/styles.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1200,
          padding: 20,
          minHeight: `70vh`
        }}
      >
          <main>{children}</main>
      </div>
      <footer style={{
        marginTop: '80px',
        display: "flex",
        justifyContent: "space-around",
        alignItems: 'center'
      }}>
        <div style={{
          maxWidth: 1200,
          width: '100%',
          padding: 15,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <div>
            By Oriol Hilari © {new Date().getFullYear()}
            {` `}
          </div>
          <div className="links-footer">
            <div> 
              <a href="https://github.com/hilaring" alt="GitHub | Oriol Hilari" title="GitHub | Oriol Hilari" target="_blank" rel="noreferrer" className="icon-link">
                <FontAwesomeIcon icon={faGithub} size="2x" />
              </a>
            </div>
            <div>
            <a href="https://www.linkedin.com/in/oriol-hilari/" alt="LinkedIn | Oriol Hilari" title="LinkedIn | Oriol Hilari" target="_blank" rel="noreferrer" className="icon-link">
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
            </div>
          </div>
        </div>

      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
